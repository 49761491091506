.badge-gold{
    color: #fff;
    background-color: #B38B59;
}

.overflow{
    white-space: nowrap; 
    width: '100%'; 
    overflow: hidden;
    text-overflow: clip; 
}

.main-container{
    flex: 10;
    margin: 15px;
    width: '100%';
    background-color:#f4f6f9;
}

.grid-container{
    display: grid;
    column-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
}

.card{
    width: '100%';
}

